import Vue from 'vue'
import App from './App.vue'

import 'bootstrap';
import 'bootstrap/scss/bootstrap.scss';

import {library} from "@fortawesome/fontawesome-svg-core";
import {faLinkedin, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faChevronDown, faPhone} from "@fortawesome/free-solid-svg-icons";

library.add(faPhone);
library.add(faWhatsapp);
library.add(faLinkedin);
library.add(faChevronDown);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
}).$mount('#app')
