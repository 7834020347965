<template>
  <div>

    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container pt-3">
        <div class="d-flex w-100">
          <div class="flex-md-grow-1">
            <a class="navbar-brand" href="/">
              <img height="64" src="@/assets/logo.png" class="rounded" alt="Software Agency logo"/>
            </a>
          </div>
          <div class="flex-md-shrink-1 pt-lg-2">
            <ul class="navbar-nav align-middle">
              <li class="nav-item">
                <a class="nav-link fw-bold text-center">
                  Afspraak? Stuur een
                  <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>
                  WhatsApp'je naar <a href="https://wa.me/31625275510">+31 6 25 275 510</a>.
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <div class="container">
      <div class="row my-5">
        <div class="col">
          <div class="">
            <h1 class="display-5 fw-bold">Software Agency</h1>
            <p class="fs-4">No Nonsense Maatwerk Software. </p>
            <hr/>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-4 col-12 mb-5">
          <h2 class="fs-4 mb-md-5 mb-3">
            <span class="bg-primary rounded text-light p-1 px-3 text-center me-3">1</span>
            Tekentafel
          </h2>
          <ul>
            <li class="mb-3">
              Kom langs, maak kennis en bespreek je wensen in onze overleg jungle.
            </li>
            <li class="mb-3">
              Op basis van je wensen schetsen we mogelijke oplossingen uit.
            </li>
            <li class="mb-3">
              Wellicht vereist het project dat we in korte tijd een aantal prototypes
              uitwerken om de mogelijke oplossingen te valideren.
            </li>
            <li class="mb-3">
              Als wij en jullie eens zijn over de oplossingsrichting, schetsen we het project uit in een offerte.
            </li>
          </ul>
        </div>

        <div class="col-md-4 col-12 mb-5">
          <h2 class="fs-4 mb-md-5 mb-3">
            <span class="bg-primary rounded text-light p-1 px-3 text-center me-3">2</span>
            Realisatie
          </h2>
          <ul>
            <li class="mb-3">
              Na akkoord, gaan we aan de slag.
            </li>
            <li class="mb-3">
              Het realisatie proces bestaat uit software ontwikkelen en overleggen.
              We maken wat, tonen het, en vragen wat je er van vindt.
            </li>
            <li class="mb-3">
              Kortom, we proberen zo snel mogelijk te laten zien wat we bouwen. Zo is het project tastbaar en
              maakt het overleggen makkelijk.
            </li>
            <li class="mb-3">
              Zijn we klaar, dan lopen we het project gezamenlijk even na.
            </li>
          </ul>
        </div>

        <div class="col-md-4 col-12">
          <h2 class="fs-4 mb-md-5 mb-3">
            <span class="bg-primary rounded text-light p-1 px-3 text-center me-3">3</span>
            Oplevering
          </h2>
          <ul>
            <li class="mb-3">
              Als de software gereed is, dan moeten de apps naar de Google Play store en de Apple App Store.
            </li>
            <li class="mb-3">
              Als het Cloud software is, deployen we het naar AWS, Google Cloud, Azure of Heroku.
            </li>
            <li class="mb-3">
              Nieuwe ide&euml;en? Terug naar de tekentafel!
            </li>
            <li class="mb-3">
              We kunnen helaas niet meegroeien met elk bedrijfsavontuur. Heb je een groter software team nodig, dan
              ondersteunen we je met liefde d.m.v. een warme overdracht en goed documentatie!
            </li>
          </ul>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col text-center">
          <font-awesome-icon :icon="['fa', 'chevron-down']" size="3x" class="text-primary"/>
        </div>
      </div>
    </div>

    <div class="container pb-4 mb-4">
      <div class="row">
        <h2 class="fs-4 mb-3 text-center">
          Veelgestelde Vragen
        </h2>
      </div>
      <div class="row">
        <div class="accordion accordion-flush" id="accordionFlushExample">

          <div v-for="(item, index) in faq" :key="index" class="accordion-item">
            <h2 class="accordion-header" :id="'flush-heading' + index">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      :data-bs-target="'#flush-collapse' + index" aria-expanded="false"
                      :aria-controls="'flush-collapse' + index">
                {{ item.question }}
              </button>
            </h2>
            <div :id="'flush-collapse' + index" class="accordion-collapse collapse"
                 :aria-labelledby="'flush-heading' + index"
                 data-bs-parent="#accordionFlushExample">
              <div class="accordion-body" v-html="item.answer">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container my-5">
      <div class="row">
        <div class="col text-center">
          <hr/>
          <cookie-law theme="base"></cookie-law>
          <p class="pt-5">
            <a target="_blank"
               href="https://softwareagency.s3.eu-central-1.amazonaws.com/algemenevoorwaarden.pdf">Algemene Voorwaarden</a>
          </p>
          <p>
            <a href="mailto:launch@softwareagency.nl">launch@softwareagency.nl</a> -
            <font-awesome-icon :icon="['fa', 'phone']" fixed-width/>
            <font-awesome-icon :icon="['fab', 'whatsapp']" fixed-width size="lg"/>
            <a target="_blank" href="https://wa.me/31625275510">+31 6 25 275 510</a> -

            <a target="_blank" href="https://www.linkedin.com/company/the-software-agency/about/?viewAsMember=true">
              <font-awesome-icon :icon="['fab', 'linkedin']" fixed-width size="lg"/>
            </a>
          </p>
          <p>
          </p>
          <p>
            &copy; 2019 - {{ new Date().getFullYear() }} Software Agency - Bitbaas B.V. (63454505)
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

export default {
  name: 'Welcome',
  props: {
    msg: String
  },
  components: {
    CookieLaw,
  },
  data() {
    return {
      faq: [
        {
          question: "Welke programeertalen gebruiken jullie?",
          answer: "Alles wat modern is. We werken programmeertaal-agnostisch, kortom elke moderne programeertaal deployen " +
              "we naar moderne Cloud-omgevingen",
        },
        {
          question: "Bouwen jullie voor de Cloud?",
          answer: "Yes, graag zelfs. We geloven dat als je software ontwikkelt dat je elk component zijn eigen " +
              "verantwoordelijkheid geeft. Je bouwt op deze manier een `decoupled architecture`. Het komt er op neer" +
              " dat je makkelijk bugs kunt oplossen en updates en veranderingen kan doorvoeren zonder dat andere " +
              "onderdelen van je systeem onnodig omvallen.",
        },
        {
          question: "Bouwen jullie ook mobiele apps?",
          answer: "Ja zeker, we maken graag gebruik van frameworks waarbij we een codebase schrijven, " +
              "maar kunnen compileren naar iOS en Android tegelijkertijd."
        },
        {
          question: "Werken jullie ook aan projecten met AI, Neurale Netwerken, Blockchain en " +
              "hyperschaalbare platformen?",
          answer: "Ja zeker, we zoeken graag de uitdaging op. We geloven dat elke uitdaging ons sterker maakt. " +
              "We hebben ervaring met het implementeren van Computer Vision systemen. Weet je ons uit te dagen?"
        },
        {
          question: "Als jullie maatwerk software ontwikkelen, bouwen jullie alles van de grond af aan op?",
          answer: "Nee, we houden niet van telkens het wiel opnieuw uitvinden. De keuze voor programmeertalen en " +
              "platformen hangt sterk af welke bestaande open-source libraries en codebases we kunnen herbruiken."
        },
        {
          question: "Wie is jullie doelgroep, eigenlijk?",
          answer: "We zijn gespecialiseerd in het ontwerpen van de software architectuur en de basis van nieuwe " +
              "software uitwerken dat het toekomstbestendig is. Dit kunnen projecten zijn voor zowel start-ups, " +
              "MKB als grootzakelijke bedrijven."
        },
        {
          question: "Mijn vraag staat er niet tussen!",
          answer: "Ah shit, dat is vervelend! Bel ons even op <a target=\"_blank\" href=\"https://wa.me/31625275510\">+31 6 25 275 510</a> of stuur een mailtje naar " +
              "<a href='mailto:launch@softwareagency.nl'>launch@softwareagency.nl</a>. Als het een generieke vraag is, dan zetten we hem hier tussen."
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";


a {
  text-decoration: none !important;
}

a:hover {
  color: $secondary !important;
}
</style>